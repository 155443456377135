import { type AppSection, roles, type Role, rolesById } from '@knapsack/types';
import { enums, Describe } from 'superstruct';

// Superstruct runtime validation for Role
export const RolesEnums = enums(roles.map((role) => role.id));
export const RolesEnumsStruct: Describe<Role> = RolesEnums;

export const isRoleValid = (role: string): role is Role =>
  RolesEnumsStruct.is(role);

/**
 * IMPORTANT: Only includes roles that can be granted by users.
 *
 * If you need all system roles, use `import { roles } from '@knapsack/types'`
 */
export const userRoles = roles.filter((r) => r.hierarchy > 0);

export function getUserRoleInfo(userRole: Role) {
  return rolesById[userRole];
}

/**
 * User Role Permissions
 */
export const canRoleAdmin = (role: Role): boolean => role === 'ADMIN';

export const canRoleDelete = (role: Role): boolean =>
  rolesById[role]?.permissions?.canDelete ?? false;

export const canRoleEdit = (role: Role): boolean =>
  rolesById[role]?.permissions?.canEdit ?? false;

export const canRoleEditSection = ({
  userRole,
  section,
}: {
  userRole: Role;
  section: AppSection;
}): boolean => {
  return rolesById[userRole]?.sectionAccess?.[section]?.canEdit ?? false;
};

export const canRoleManageUsers = (role: Role): boolean =>
  rolesById[role]?.permissions?.canManageUsers ?? false;

export const canRolePublish = (role: Role): boolean =>
  rolesById[role]?.permissions?.canPublish ?? false;

export const canRoleView = (role: Role): boolean =>
  rolesById[role]?.permissions?.canView ?? false;
