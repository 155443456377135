import type { SiteInstanceStatuses_Enum } from '@knapsack/hasura-gql-client';

export const instanceStatusesThatCanEdit: SiteInstanceStatuses_Enum[] = [
  'DRAFT',
];

export const instanceStatusesThatCanPublish: SiteInstanceStatuses_Enum[] = [
  ...instanceStatusesThatCanEdit,
  'PROPOSED',
];

export const instanceStatusesThatCannotBeAccessed: SiteInstanceStatuses_Enum[] =
  ['DELETED', 'PUBLISHED', 'PUBLISHING'];

export const canInstanceStatusBeAccessed = ({
  instanceStatus,
}: {
  instanceStatus: SiteInstanceStatuses_Enum;
}): boolean => !instanceStatusesThatCannotBeAccessed.includes(instanceStatus);

export const canInstanceStatusEdit = ({
  instanceStatus,
}: {
  instanceStatus: SiteInstanceStatuses_Enum;
}): boolean => instanceStatusesThatCanEdit.includes(instanceStatus);

export const canInstanceStatusPublish = ({
  instanceStatus,
}: {
  instanceStatus: SiteInstanceStatuses_Enum;
}): boolean => instanceStatusesThatCanPublish.includes(instanceStatus);
