/**
 * @file
 *
 * All things involving low-level user JWT parsing, claims, roles.
 */

import {
  array,
  boolean,
  Describe,
  enums,
  number,
  object,
  optional,
  string,
  type,
  union,
  record,
  assign,
} from 'superstruct';
import { type Role } from '@knapsack/types';
import { isRoleValid, RolesEnums } from './user-roles';

/**
 * Please note that these are not the same as the "roles" used on a per-site
 * basis, these are roles for the entire DB.
 */
export enum HasuraRoles {
  // yes, `admin` exists but no reason anyone should have it, yet
  user = 'user',
  anonymous = 'anonymous',
}
const HasuraRolesStruct = enums(Object.values(HasuraRoles));

export type HasuraClaims = {
  'x-hasura-allowed-roles': HasuraRoles[];
  'x-hasura-default-role': HasuraRoles;
  'x-hasura-user-id'?: string;
};
// Superstruct runtime validation for HasuraClaims
export const HasuraClaimsStruct: Describe<HasuraClaims> = object({
  'x-hasura-allowed-roles': array(HasuraRolesStruct),
  'x-hasura-default-role': HasuraRolesStruct,
  'x-hasura-user-id': optional(string()),
});

/**
 * The specific Knapsack claims that live at 'https://knapsack.cloud' within JWT
 */
export type KnapsackClaims = {
  userId: string;
  /** For email address that end in `knapsack.cloud` */
  isSuperAdmin: boolean;
  /**
   * Keys are roleId, value are array of siteId strings
   * Purposely kept small for JWT payloads. Often expanded.
   * @see {SiteRoleMap}
   * @see {expandSitesByRole}
   * @example
   * {
   *   sitesByRole: {
   *      EDITOR: ['ks-demo-bootstrap', 'ks-demo-tailwind'],
   *      VIEWER: ['site-id-1'],
   *   },
   * }
   */
  sitesByRole: {
    [roleId in Role]?: string[];
  };
};
// Superstruct runtime validation for KnapsackClaims
export const KsClaimsStruct: Describe<KnapsackClaims> = object({
  userId: string(),
  isSuperAdmin: boolean(),
  sitesByRole: record(RolesEnums, array(string())),
});

/**
 * The easier to work with & expanded version of `sitesByRole` in KsClaims,
 * which is kept as small as possible for JWT payloads.
 * @see {KnapsackClaims}
 */
export type SiteRoleMap = {
  [siteId: string]: Role;
};

/**
 * Base JWT
 */
export type JwtBase = {
  /* Token expiration time; usually 1 hour after issued at time (`iat`), in seconds (not ms) */
  exp: number;
  /* Email exists on ID token, but not AUTH token */
  email?: string;
  /* WARNING: email_verified is not guaranteed to exst on SSO tokens, and is boolean or string! */
  email_verified?: string | boolean;
  /* Issued at Time, in seconds (not ms) */
  iat: number;
  /* Issuer */
  iss: string;
  /* Subject (userId) */
  sub: string;
  /* Audience */
  aud: string | string[];
};
// Superstruct runtime validation for JwtBase. Use type() to ignore extra keys
export const JwtBaseStruct: Describe<JwtBase> = type({
  exp: number(),
  email: optional(string()),
  email_verified: optional(union([string(), boolean()])),
  iat: number(),
  iss: string(),
  sub: string(),
  aud: union([string(), array(string())]),
});

/**
 * Bring it all together to make a full JWT
 */
export const HASURA_CLAIMS_NAMESPACE = 'https://hasura.io/jwt/claims';
export const KNAPSACK_CLAIMS_NAMESPACE = 'https://knapsack.cloud';

// Just custom claims
export type JwtCustomClaims = {
  [HASURA_CLAIMS_NAMESPACE]: HasuraClaims;
  [KNAPSACK_CLAIMS_NAMESPACE]: KnapsackClaims;
};
// Superstruct runtime validation for JwtCustomClaims
export const JwtCustomClaimsStruct: Describe<JwtCustomClaims> = object({
  [HASURA_CLAIMS_NAMESPACE]: HasuraClaimsStruct,
  [KNAPSACK_CLAIMS_NAMESPACE]: KsClaimsStruct,
});

// The entire JWT, custom claims + base root keys
export type JwtWithCustomClaims = JwtBase & JwtCustomClaims;
// Superstruct runtime validation for JwtWithCustomClaims
export const JwtWithCustomClaimsStruct: Describe<JwtWithCustomClaims> = assign(
  JwtBaseStruct,
  JwtCustomClaimsStruct,
);

/**
 * Convert sitesByRole to be a map of siteId to roleId. This is the format we
 * actually use within the app to determine what sites a user can access.
 */
export function expandSitesByRole(
  sitesByRole: KnapsackClaims['sitesByRole'],
): SiteRoleMap {
  return Object.entries(sitesByRole).reduce((cur, [roleId, siteIds]) => {
    if (!isRoleValid(roleId)) return cur;
    siteIds.forEach((siteId) => {
      cur[siteId] = roleId;
    });
    return cur;
  }, {} as SiteRoleMap);
}

export type KsClaimsHelper = Omit<KnapsackClaims, 'sitesByRole'> & {
  siteRoleMap: SiteRoleMap;
  /** Get the User's Role for particular site */
  getSiteRole: (siteId: string) => Role;
};

export const defaultAnonymousKsClaims: KsClaimsHelper = {
  isSuperAdmin: false,
  userId: '',
  siteRoleMap: {},
  getSiteRole: () => 'ANONYMOUS',
};

/**
 * Picks off the KsClaims from the JWT (since it has a weird to use
 * `https://knapsack.cloud` namespace) and then also converts the size-conscious
 * `sitesByRole` into the more usable format of `siteRoleMap
 */
export const getKsClaims = (claims: unknown): KsClaimsHelper => {
  if (!JwtWithCustomClaimsStruct.is(claims)) {
    return defaultAnonymousKsClaims;
  }
  const { sitesByRole, ...ksClaims } = claims[KNAPSACK_CLAIMS_NAMESPACE];
  const siteRoleMap = expandSitesByRole(sitesByRole);
  return {
    ...ksClaims,
    siteRoleMap,
    getSiteRole: (siteId) => {
      if (ksClaims.isSuperAdmin) return 'ADMIN';
      return siteRoleMap[siteId] || 'ANONYMOUS';
    },
  };
};
